.about {
  font-weight: 400;
  font-size: 18px;
  font-style: Inter, sans-serif !important;
  line-height: 26px;
  text-align: justify;
}
.Massi {
  font-weight: 700;
  text-align: center;
}

.privacyTopBoxWrap {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding: 15px 0 48px;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.privacyTaglineText,
.privacyTopBoxHeading {
  font-style: normal;
  text-transform: capitalize;
}
.privacyTopBoxHeading {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #ffc50a;
  margin: 0 0 6px;
}
.privacyTaglineText {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #000;
  opacity: 0.6;
  margin: 0;
}

.privacyContentWrap {
  display: -webkit-flex;
  display: flex;

  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;

  width: 100%;
}
.privacyContentWrap .insideGridContainer {
  max-width: 90%;
  margin: auto;
}

.privacyContentWrap .insideGridContainer h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 170%;
  text-transform: uppercase;
  color: #000;

  margin: 0 0 12px;
}
.privacyContentWrap .insideGridContainer h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;

  line-height: 170%;
  text-transform: uppercase;
  color: #000;

  margin: 0 0 12px;
}
.privacyContentWrap .insideGridContainer p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  color: #000;
  opacity: 0.8;
  margin: 0 0 20px;
}
