.searchInputs {
  display: flex;
}
.slick-prev:before,
.slick-next:before {
  font-family: 'slick' !important;
  font-size: 20px !important;
  color: black !important;
  line-height: 1 !important;
  opacity: 0.75 !important;

  -webkit-font-smoothing: antialiased;
}
.search input {
  background-color: white;
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 18px;

  padding: 15px;
  height: 30px;
  width: 300px;
  border: 2px solid #000;
  box-sizing: border-box;
  border-radius: 11px;
  max-width: 245px;
  height: 42px;
  display: flex;
  padding: 11px 13px;
  margin: 0 26px 0 0;
  background: #fff;
}
.search input::placeholder {
  color: rgb(255, 197, 10);
}

.searchIcon {
  width: 40px;
  color: black;
  display: grid;

  place-items: center;
}

input:focus {
  outline: none;
}
.searchIcon svg {
  font-size: 35px;
  margin-top: -10px;
  margin-left: -25px;
}

.dataResult {
  margin-top: 5px;
  margin-left: -13px;
  width: 245px;
  border-left: 3px solid #edb300;
  border-right: 3px solid #edb300;
  position: absolute;
  z-index: 2;
  max-height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: scroll;
}

.dataResult h3 {
  color: #edb300;
  font-weight: 800;
}
.dataResult h4 {
  color: black;
  font-weight: 800;
}
@media (min-width: 180px) and (max-width: 820px) {
  .dataResult {
    width: 90%;
  }
}
.dataResult::-webkit-scrollbar {
  display: none;
}
.dataResult .dataItem {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  color: black;
}
.dataResultHomePageBanner {
  margin-top: 5px;
  margin-left: -13px;
  width: 43%;
  border-left: 3px solid #edb300;
  border-right: 3px solid #edb300;
  position: absolute;
  z-index: 2;
  max-height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: scroll;
}

.dataResultHomePageBanner h3 {
  color: #edb300;
  font-weight: 800;
}
@media (min-width: 180px) and (max-width: 600px) {
  .dataResultHomePageBanner {
    width: 100%;
  }
}
@media (min-width: 600px) and (max-width: 912px) {
  .dataResultHomePageBanner {
    width: 80%;
  }
}
@media (min-width: 1024px) {
  .dataResultHomePageBanner {
    width: 60%;
  }
}
@media (min-width: 1228px) {
  .dataResultHomePageBanner {
    width: 50%;
  }
}
@media (min-width: 1280px) {
  .dataResultHomePageBanner {
    width: 43%;
  }
}
@media (min-width: 1536px) {
  .dataResultHomePageBanner {
    width: 43%;
  }
}
@media (min-width: 1706px) {
  .dataResultHomePageBanner {
    width: 43%;
  }
}
.dataResultHomePageBanner::-webkit-scrollbar {
  display: none;
}
.dataResultHomePageBanner .dataItem {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  color: black;
}
.stateResult {
  margin-top: 623px;
  margin-left: -13px;
  width: 270px;
  position: absolute;
  z-index: 1;
  border-left: 3px solid #2a8bf2;
  border-right: 3px solid #2a8bf2;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}
.stateResult h3 {
  color: #2a8bf2;
  font-weight: 800;
}
.keywordResult {
  margin-top: 417px;
  margin-left: -13px;
  width: 270px;
  position: absolute;
  z-index: 2;
  border-left: 3px solid #0f9f1d;
  border-right: 3px solid #0f9f1d;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}
.keywordResult h3 {
  color: #0f9f1d;
  font-weight: 800;
}
.categoryResult {
  margin-top: 211px;
  margin-left: -13px;
  width: 270px;
  position: absolute;
  z-index: 2;
  border-left: 3px solid #9510b8;
  border-right: 3px solid #9510b8;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}
@media (min-width: 180px) and (max-width: 820px) {
  .categoryResult {
    width: 90%;
    margin-left: -20px;
  }
}

@media (min-width: 180px) and (max-width: 820px) {
  .keywordResult {
    width: 90%;
    margin-left: -20px;
  }
}
@media (min-width: 180px) and (max-width: 820px) {
  .stateResult {
    width: 90%;
    margin-left: -20px;
  }
}
.categoryResult h3 {
  color: #9510b8;
  font-weight: 800;
}

.keywordResult::-webkit-scrollbar {
  display: none;
}
.categoryResult::-webkit-scrollbar {
  display: none;
}
.stateResult::-webkit-scrollbar {
  display: none;
}

.keywordResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}
.categoryResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}
.stateResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}

.dataItem p {
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
a {
  text-decoration: none;
}

#clearBtn {
  cursor: pointer;
}
.products-container {
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.card {
  width: 50%;
  display: flex;
  border-radius: 10px;
  flex-direction: row;
  flex-wrap: nowrap;
}
