.cookie-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}

.cookie-table th,
.cookie-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.cookie-table th {
  background-color: #f2f2f2;
}

.cookie-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.cookie-table a {
  color: #0645ad;
}
