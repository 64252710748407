/* .exploreBgWrap {
  background: lightslategrey;
} */
.explorePageTopMassiLogo {
  margin: auto;
  text-align: center;
  padding: 35px 0;
  filter: invert();
}
.exploreTopImgGallery {
  position: relative;
}
/* .exploreTopImgGallery .exploreGalleryWrap {
  position: relative;
  border-radius: 10px;
  height: 360px;
  overflow: hidden !important;
}
.exploreTopImgGallery .exploreGalleryWrap:before {
  background: #383838;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
} */
.exploreContentWrap {
  margin: 35px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.exploreContentWrap .exploreLeftHeading h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 26px;
  color: black;
  font-family: roboto;
  margin: 35px 0 25px;
}
.exploreContentWrapGallery {
  margin: 35px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: lightgoldenrodyellow;
  border-radius: 10px;
  margin: auto;

  max-width: 135rem;
}

.exploreContentWrapGallery .exploreLeftHeading h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 26px;
  color: black;
  font-family: roboto;
  margin: 35px 0 25px;
}
.exploreCarouselWrapper,
.exploreImgCarouselWrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.exploreCarouselWrapper {
  width: 100%;
  margin: 0;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.exploreImgCarouselWrap {
  position: relative;
  max-width: 150px;
}
.exploreCarouselWrapper,
.exploreImgCarouselWrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.carouselArrowWrap {
  display: -webkit-flex;
  display: flex;
  filter: invert();
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  /* width: 100%; */
  margin: 35px 0 0;
}
.exploreRightTopWrap .rightSideDetailsWrap {
  margin: 25px 0 0;
}

.rightSideDetailsWrap {
  margin: 54px 0 0;
}
.exploreRightTopWrap .rightSideDetailsWrap .contactVendorBtn {
  margin: auto;
  text-align: center;
}
.exploreCantFindWrap {
  max-width: 464px;
  margin: auto;

  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 100px 0;
}
.exploreCantFindWrap .cantFindBtn,
.exploreCantFindWrap p {
  font-family: roboto;
  font-style: normal;
  font-weight: 500;
  text-align: center;
}
.exploreCantFindWrap p {
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
  color: black;
}
.exploreCantFindWrap .cantFindBtn {
  background: white;
  height: 66px;
  font-size: 19px;
  line-height: 28px;
  color: black;
  border: 1px solid black;
  border-radius: 10px;

  width: 100%;
}
.exploreCantFindWrap .cantFindBtn,
.exploreCantFindWrap p {
  font-family: roboto;
  font-style: normal;
  font-weight: 500;
  text-align: center;
}
.exploreRightTopWrap .rightSideDetailsWrap .contactVendorBtn button {
  background: black;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  height: 60px;
  text-align: center;
  letter-spacing: 0.164767px;
  font-family: roboto;
  color: white;
  width: 80%;
}
.contactVendorBtn button {
  border-radius: 8.23834px;
}
.exploreDealWrap .vendorDeals {
  margin: 0;
  padding: 50px 0;
}
/* .vendorDeals {
  margin: 0 0 50px;
} */
.dealsHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  border-bottom: 0 solid #edb300;
  padding: 0 0 12px;
  letter-spacing: 1.68px;
  color: #000;
  margin-bottom: 3px;
  position: relative;
}
.dealsHeading:before {
  content: '';
  background: #edb300;
  width: 17%;
  height: 1px;
  bottom: 4px;
  position: absolute;
  left: 13%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.exploreDealWrap .vendorDeals .dealsHeading img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.exploreDealBoxWrap .dealBoxWrap {
  max-width: 675px;
  background: #323232;
  opacity: 1;
}
.dealBoxWrap {
  display: flex;
  background: #fff6da;
  border-radius: 2px;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  /* padding: 0px 0px; */
  padding: 20px;
  margin: 0 0 20px 57px;
  /* padding: 37px 50px;
  margin: 0 0 20px 57px; */
  position: relative;
  border: 2px dashed orange;
}
.dealBoxWrap:after,
.dealBoxWrap:before {
  content: '';
  width: 50px;
  height: 50px;
  position: absolute;
  top: 25%;
  border-radius: 100%;
}
.dealBoxWrap:before {
  left: -30px;
  background: white;
  /* background-size: cover; */
}

/* @media (min-width: 180px) and (max-width: 480px) {
  .vendorMetaWrap {
    margin: 18px 0 25px 60px;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
  }
} */
.costlyExplore {
  float: right;
  font-weight: 700;
}
.explore-heading {
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
}
/* .displayInterest h1 {
  display: none;
}
@media (min-width: 360px) and (max-width: 1023px) {
  .explore-heading {
    display: none;
  }
  .explorePosition {
    display: none;
  }
  
} */
@media (max-width: 768px) {
  .image-gallery-bullets .image-gallery-bullet {
    margin: 6px !important;
    padding: 2px !important;
  }
}
