Common styles for both desktop and mobile .signup-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.text-side {
  flex: 1;
  background-color: aliceblue;
  padding: 20px;
  min-height: 72rem;
}

/* Desktop view: show the image with a smaller size */
.image-side {
  flex: 1;
  background-color: #ffeecc;

  background-size: 50% 50%;
}

/* Mobile view: hide the image */
@media (max-width: 768px) {
  .image-side {
    display: none;
  }
}

.image-container {
  max-width: 50%; /* Adjust the image width as needed */
  background-color: #fff7e1; /* Cream background color */
  padding: 20px; /* Add padding to create space around the image */
}

.image-container img {
  width: 100%;
  height: auto;
}

.container-fluid {
  display: flex; /* Use flexbox to align the two divs side by side */
  justify-content: space-between; /* Space between the two divs */
}

.text-container {
  flex: 1; /* Take up available space in the container */
}

.input-text-signup {
  height: 42px;
  /* line-height: 51px; */
  padding: 0 8px;
  outline: none;
  font-size: 15px;
  color: #808080;
  /* margin: 0 0 16px 0; */
  max-width: 30%;
  min-width: 30%;
  box-sizing: border-box;
  display: block;
  margin: auto;
  margin-top: 20px;
  /* display: flex; */
  background-color: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 1px 3px 0px rgb(0 0 0 / 6%);
  font-weight: 500;
  opacity: 1;
  border-radius: 14px;
}
@media (max-width: 768px) {
  /* Adjust the breakpoint as needed */
  .input-text-signup {
    max-width: 100%;
    min-width: 100%;
  }
}

.input-text-signup-categories {
  /* height: 42px; */
  /* line-height: 51px; */
  padding: 0 8px;
  outline: none;
  font-size: 15px;
  color: #808080;
  /* margin: 0 0 16px 0; */
  max-width: 30%;
  min-width: 30%;
  box-sizing: border-box;
  display: block;
  margin: auto;
  margin-top: 20px;
  /* display: flex; */
  background-color: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: 0 1px 3px 0px rgb(0 0 0 / 6%);
  font-weight: 500;
  opacity: 1;
  border-radius: 14px;
}
@media (max-width: 768px) {
  /* Adjust the breakpoint as needed */
  .input-text-signup-categories {
    max-width: 100%;
    min-width: 100%;
  }
}

.customFormGroup .signUpBtn-signup {
  background: #000;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;

  color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0;
  min-width: 30rem;
  margin-top: 2rem;
  height: 39px;
  /* margin: 22px 0 0; */
  font-family: roboto;
}

.customFormGroup .signUpBtn-signup-NewUser {
  background: #000;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;

  color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0;
  min-width: 25rem;
  margin-top: 5rem;
  height: 39px;
  /* margin: 22px 0 0; */
  font-family: roboto;
}
@media (max-width: 768px) {
  /* Adjust the breakpoint as needed */
  .customFormGroup .signUpBtn-signup-NewUser {
    min-width: 30rem;
    margin-top: 2rem;
  }
}

.customFormGroup .signUpBtn-signup-NewUser-Signup {
  background: #f0f8ff;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;

  color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0;
  min-width: 25rem;
  margin-top: 3rem;
  height: 39px;
  /* margin: 22px 0 0; */
  font-family: roboto;
}
@media (max-width: 768px) {
  /* Adjust the breakpoint as needed */
  .customFormGroup .signUpBtn-signup-NewUser-Signup {
    min-width: 30rem;
    margin-top: 2rem;
  }
}

.box-container {
  flex: 1;
  background-color: #ffeecc; /* Set the background color to cream */
  min-height: 72rem;
  max-width: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-content {
  text-align: center; /* Center the text horizontally */
  right: 218px;
  bottom: 70px;
}

.box-image {
  width: 34rem;
  max-height: 100%;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .box-container {
    display: none;
  }
}

.signUpWithBox-signup span {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  font-family: roboto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  background: aliceblue;
  color: #000;
  display: inline-block;
  z-index: 1;
  padding: 0 17px;
}
.signupNew span {
  text-align: center;
  position: relative;
}

.signupNew span {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  font-family: roboto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  background: aliceblue;
  color: #000;
  display: inline-block;
  z-index: 1;
  padding: 0 17px;
}
@media (max-width: 768px) {
  /* Adjust the breakpoint as needed */
  .customFormGroup {
    flex-direction: column-reverse; /* Reverse the order on mobile view */
  }
}
