.BrowseVendordataResult {
  margin-top: 5px;
  margin-left: 0px;
  width: 33%;

  position: absolute;
  z-index: 2;
  max-height: 200px;

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: auto;
}

.BrowseVendordataResult .dataItem {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  color: black;
}

.BrowseVendordataResult h3 {
  color: #edb300;
  font-weight: 800;
}

@media (min-width: 180px) and (max-width: 820px) {
  .BrowseVendordataResult {
    width: 87%;
    margin-left: 3px;
  }
}
@media (width: 1024px) {
  .search .vendorSearch {
    min-width: 91rem;
  }
}
.search .vendorSearch {
  background: #fff;
  border: none;
  border-radius: 11px;
  box-sizing: border-box;
  color: #000;
  font-family: roboto;
  font-size: 15px;
  font-style: normal;
  height: 50px;
  letter-spacing: 0.2px;

  text-indent: 0px;
  margin: 0 11px 0 0;
  padding: 17px 20px;
}

.search .vendorSearch:focus {
  background: #fff;
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  color: #000;
  font-family: roboto;
  font-size: 15px;
  font-style: normal;
  height: 50px;
  letter-spacing: 0.2px;
  text-indent: 0px;
  margin: 0 11px 0 0;
  padding: 17px 20px;
}
.search .vendorSearch::placeholder {
  color: hsl(0, 0%, 50%);
  text-indent: 0px;
}
