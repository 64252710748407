/* .stickys {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
} */
.topHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
.join {
  background: #ffc50a;
  border-radius: 71px;
  font-weight: 600;
  font-size: 15px;
  width: 150px;
  padding: 8px 6px;
  border: 0;
  cursor: pointer;
  text-transform: uppercase;
  color: white;
}
@media (max-width: 767px) {
  .join {
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.list {
  text-decoration: none;
  display: flex;
  list-style: none;
  justify-content: center;

  opacity: 0.7;
  font-size: 13px;
}
/* .list:hover {
  opacity: 1;
} */
.list li {
  padding: 5px;
}

.paragraph {
  font-size: 16px;
  color: white;
  opacity: 0.6;
}

@media (max-width: 767px) {
  #footer ul.social-icons,
  .listing-share ul.social-icons,
  #top-bar ul.social-icons {
    margin-left: 50px;
  }
}

.display-4 {
  font-weight: 800;

  text-align: center;
}
.display-5 {
  width: 901px;
  height: 38px;
  margin-left: 35px;
  margin-top: 100px;

  font-style: normal;
  font-weight: 700;
  font-size: 32px;
}
.feedback {
  background-color: #fffae9;
}

.borderfaq {
  padding: 2px, 0px, 2px, 0px;
  height: 3px;
  gap: 10px;
  width: 80%;
  margin-top: 30px;
  margin-left: 35px;
  background-color: #c7c7c7;
}
.questions {
  text-align: center;
  /* font-family: 'roboto' !important; */
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-transform: capitalize;
}
.contact {
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-top: 20px;
  font-size: 11px;
  align-items: center;
  padding: 12px 32px;
  gap: 10px;
  color: white;
  width: 119.84px;
  height: 39.07px;
  text-decoration: none;
  left: 514px;
  top: 1435.16px;
  border: none;
  background: #ffc727;
  border-radius: 6px;
}

.Browse {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.1px;
  color: #252b42;
  margin: 0;
  /* font-family: 'roboto' !important; */
}
.Browse2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.1px;
  color: #252b42;
  margin: 0;
  /* font-family: 'roboto' !important; */
  display: none;
}
@media (min-width: 180px) and (max-width: 480px) {
  .Browse {
    display: none;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .Browse2 {
    display: block;
    text-align: center;
  }
}
.moving {
  margin-left: 18rem;
  margin-right: 20px;
}
.signup {
  border-radius: 10px;

  padding: 10px 0px 10px 3px;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 3.5em;
  height: 3.5em;
  border: none;
  /* border-style: unset; */
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

/* footer */
@media (min-width: 481px) and (max-width: 767px) {
  .customFooter {
    background: #000;
    padding: 37px 0 11px;
  }
}
.customFooter {
  background: #000;
  /* padding: 58px 0 11px; */
  padding: 27px 0 0px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
@media (min-width: 481px) and (max-width: 767px) {
  .footerLogo {
    width: 220px;
  }
}
.footer1stPara,
.joinUsBtn {
  font-style: normal;
  line-height: 24px;
  color: #fff;
  /* font-family: 'roboto' !important; */
}

.footer1stPara {
  font-size: 16px;
  margin: 0 0 34px;
  opacity: 0.6;
  padding: 0;
  max-width: 422px;
  font-weight: 400;
}
.joinUsBtn {
  background: #ffc50a;
  border-radius: 4px;
  font-weight: 600;
  font-size: 15px;
  /* width: 151px; */
  padding: 8px 46px;
  border: 0;
  text-transform: uppercase;
  /* margin-bottom: 44px; */
}
.footerLinks {
  padding: 13px 0 0 50px;
}
.separator {
  margin: 0 0.5rem;
  color: white;
  font-size: 1.2rem;

  font-weight: bold;
}
.footerFollowLinks {
  padding: 13px 0 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  max-width: 250px;
}
.footerBottomLinks {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.footerBottomLinks ul {
  margin: 0 0 15px;
  padding: 0;
  display: -webkit-flex;
  display: flex;
}
.footerBottomLinks ul li {
  list-style: none;
  display: inline-block;
}
.footerBottomLinks ul li a {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  /* font-family: 'roboto' !important; */
  color: #fff;
  opacity: 0.6;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
  padding: 4px;
}
.footerBottomLinks ul li a:hover {
  opacity: 1; /* set the opacity to 1 when the mouse hovers over the link */
}
.footerBottomLinks p {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 24px;
  color: #fff;
  margin: 0;
}
.footerLinks h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  /* font-family: 'roboto' !important; */
  margin-bottom: 28px;
}
.footerLinks ul li a {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  /* font-family: 'roboto' !important; */
  color: #fff;
  opacity: 0.6;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
}
.footerLinks ul li a:hover {
  opacity: 1; /* set the opacity to 1 when the mouse hovers over the link */
}
.footerFollowLinks h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  /* font-family: 'roboto' !important; */
  margin-bottom: 28px;
  text-align: right;
}
.footerFollowLinks ul li img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
  width: 33px;
}

.footerFollowLinks ul {
  margin: 0;
  padding: 0;
}
.footerFollowLinks ul li {
  list-style: none;
  /* display: inline-block;
  margin-right: 28px; */
  display: inline;
  margin-right: 18px;
  margin-left: 7px;
}
@media (min-width: 180px) and (max-width: 480px) {
  .footerLinks {
    padding: 0;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .footerLinks h1 {
    font-size: 16px;
    margin: 18px;
    /* line-height: 22px; */
    /* margin-bottom: 25px; */
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .footerLinks ul li a {
    font-size: 14px;
    line-height: 24px;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .footerFollowLinks {
    max-width: 100%;
    padding: 0px 0 30px;
    /* padding: 43px 0 0; */
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .footerFollowLinks h6 {
    text-align: left;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
  }
}

/* Navbar */
@media (min-width: 375px) and (max-width: 912px) {
  .navbar-nav {
    display: flex;
    /* text-align: center; */
    /* justify-content: center; */
  }
}

/* NAVBAR  */
.topHeader {
  /* padding: 24px 0 34px; */
  background-color: white;
  padding: 15px 0 5px;
  margin: auto;
}
.topHeader .navbar {
  padding: 0;
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}
.navbar {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }
}

.topHeader .navbar .navbar-brand {
  padding: 0;
  margin-right: 28px;
}
.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.desktopHide,
.mobileSearchForm {
  display: none;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-toggler,
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
}
.headerSearchForm {
  border: 2px solid #000;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 11px;
  max-width: 245px;
  height: 42px;
  /* border-color: #edb300; */
  display: -webkit-flex;
  display: flex;
  padding: 11px 13px;
  margin: 0px 26px 0 0;
  background: #fff;
}

.headerSearchForm img {
  margin-right: 23px;
  width: 20px;
}
.headerSearchForm input {
  border: 0;
  background: transparent;
  padding: 0;
  font-style: normal;
  /* text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); */
  margin-top: -10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #edb300;
  /* font-family: 'roboto' !important; */
}

.headerSearchFormBanner {
  border: 2px solid #000;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 11px;
  max-width: 400px;
  height: 42px;
  /* border-color: #edb300; */
  display: -webkit-flex;
  display: flex;
  padding: 11px 13px;
  margin: 0px 26px 0 0;
  background: #fff;
}

.headerSearchFormBanner img {
  margin-right: 23px;
  width: 20px;
}
.headerSearchFormBanner input {
  border: 0;
  background: transparent;
  padding: 0;
  font-style: normal;
  /* text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); */
  margin-top: -10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #edb300;
  /* font-family: 'roboto' !important; */
}

.headerSearchForms {
  border: 2px solid #000;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 11px;
  min-width: 100%;
  height: 42px;
  /* border-color: #edb300; */
  display: -webkit-flex;
  display: flex;
  padding: 11px 13px;
  margin: 0px 26px 0 0;
  background: #fff;
}

.headerSearchForms img {
  margin-right: 23px;
  width: 20px;
}
.headerSearchForms input {
  border: 0;
  background: transparent;
  padding: 0;
  font-style: normal;
  /* text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5); */
  margin-top: -10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #edb300;
  /* font-family: 'roboto' !important; */
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}
.topHeader .navbar-nav li.nav-item .nav-link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  /* padding: 0 22px 0 0; */
  padding: 0 13px 0 0;
  /* font-family: 'roboto' !important; */
}
.topHeaderRightSide {
  display: -webkit-flex;
  display: flex;
  margin-left: auto;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
}
/* @media (min-width: 180px) and (max-width: 1060px) {
  .topHeaderRightSide {
    justify-content: center;
  }
} */
.topHeaderRightSide .topHeaderRightNav {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  margin: 0 0 0 34px;
  padding: 0;
}

.topHeaderRightSide .topHeaderRightNav li {
  list-style: none;
  display: -webkit-flex;
  display: flex;
  margin: 0 15px 0 0;
}
@media (min-width: 180px) and (max-width: 1023px) {
  .desktopSearchForm {
    display: block;
    min-width: 100%;
    margin-top: 15px;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topHeader .container {
    padding: 0;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topHeader .navbar {
    margin: 0;
    padding: 0 15px 15px;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .desktopHideHeaderRightLink {
    display: -webkit-flex;
    display: flex;
    margin-left: auto;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .desktopHideHeaderRightLink {
    display: -webkit-flex;
    display: flex;
    margin-left: auto;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topHeader .navbar-nav {
    margin: 7px 0 0;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topHeader .navbar-nav li.nav-item {
    padding: 6px 0;
  }
}
.navbar-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
@media (min-width: 180px) and (max-width: 480px) {
  .topHeaderRightSide .topHeaderRightNav {
    margin: 15px 0;
  }
}
.topHeaderRightSide .topHeaderRightNav li:last-child {
  margin-right: 0;
}
.topHeaderRightSide .topHeaderRightNav li a.signUp {
  background: #000;
  border-radius: 4px;

  padding: 10px;
  color: #fff;
}
.topHeaderRightSide .topHeaderRightNav li a {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #000;
  text-decoration: none;
}
.scroll-to-top {
  box-shadow: none !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  z-index: 9999 !important;
}
@media screen and (min-width: 913px) {
  .headerSearchForms {
    display: none;
  }
}
