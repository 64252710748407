.modal-content .modal-header {
  padding: 20px;
  position: relative;
  text-align: center;
  border: 0;
  margin: auto;
  width: 100%;
}
.signUpBoxWrap .signUp .customFormGroup .form-control {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: rgba(0, 0, 0, 0.5);
}

.customFormGroup {
  display: -webkit-flex;
  display: flex;
  gap: 10px;
  margin: 0 0 20px;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}

.signUpBoxWrap {
  max-width: 364px;
  margin: auto;
}

.customFormGroup .signUp {
  background: #000;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  text-align: center;
  border: 0;
  width: 100%;
  height: 45px;
  margin: 22px 0 0;
}
.modal-content .modal-body {
  padding: 0 20px;
}
.signUpBoxWrap .signUpForm .customFormGroup .form-control {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: rgba(0, 0, 0, 0.5);
}
.signUpBoxWrap .signUpForm .form-control {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: rgba(0, 0, 0, 0.5);
}
