@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

/* .vendorPriceTagWithDealIcon .insideVendorRightSideBtn img {
  width: 500px;
} */
.homeTopPicksWrap .container {
  /* background: #fffbee; */
  /* box-shadow: 0 4px 4px rgb(0 0 0 / 25%), 0 4px 4px rgb(0 0 0 / 25%),
    0 4px 4px rgb(0 0 0 / 25%); */
  display: -webkit-flex;
  display: flex;

  width: 96%;
  /* padding: 0px 17px 13px; */
  /* padding: 21px 17px 101px; */
  padding: 21px 17px 0px;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.homeTopPicksWrap .container a {
  text-decoration: none;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .homeSecondSectionWrap {
    width: 100%;
    margin: 10px 0 20px;
  }
}

/* @media (min-width: 180px) and (max-width: 480px) {
  .topPickPrdContent h5 {
    font-size: 18px;
    line-height: 15px;
    margin-bottom: 5px;
    text-overflow: unset;
    margin-right: -58px;
  }
} */
@media (min-width: 180px) and (max-width: 480px) {
  .topPickPrdContent h1 {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 5px;
    margin-right: -6px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .homeSecondSectionWrap {
    width: 100%;
    margin: 10px 0 20px;
  }
}

@media (min-width: 180px) and (max-width: 480px) {
  .topPicksBoxWrap {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    margin-bottom: 42px;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 2px solid #dfdfdf;
    padding-bottom: 25px;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topPickLocationPriceWrap {
    margin-bottom: 15px;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topPicksBoxWrap {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    margin-bottom: 42px;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 2px solid #dfdfdf;
    padding-bottom: 25px;
  }
}
/* @media (min-width: 768px) and (max-width: 1023px) {
  .homeSecondSectionBoxWrap {
    padding: 15px 0;
    min-height: 290px;
    margin-bottom: 12px;
  }
} */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  padding: 5px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  font-family: roboto;
  color: #333;
  text-align: center;
  margin: auto;
}
.signUpWithBox,
.signUpWithBox h3 {
  text-align: center;
  position: relative;
}
.signUpWithBox {
  margin: 0px auto;
  width: 60%;
}

.socialLoginBoxes {
  display: flex;
  justify-content: center;
  gap: 13px;
  /* justify-content: space-evenly; */
}
@media (max-width: 767px) {
  .socialLoginBoxes {
    display: block;
    width: 27rem;
    margin: auto;
  }
}

.socialLoginBoxes .appleLogin {
  /* background: #111; */
  color: white;
}
.socialLoginBoxes .facebookLogin {
  background: #0f3577;
}
.socialLoginBoxes .googleLogin {
  color: black;
}

.socialLoginBoxes a,
.socialLoginBoxes button {
  /* width: 95px; */
  /* height: 95px; */

  height: 70px;
  background: #fff;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border: 1px solid #ffffff;

  box-sizing: border-box;
  /* box-shadow: 0 4.35443px 26.1266px rgb(0 0 0 / 9%); */
  border-radius: 10.8861px;
  display: -webkit-flex;
  display: flex;

  flex-wrap: nowrap;
  flex-direction: row;

  justify-content: center;

  align-items: center;
  text-decoration: none;

  flex-direction: column;
}

.socialLoginBoxesSignup {
  display: flex;
  justify-content: center;
  gap: 13px;
  margin-left: 15px;
}

.socialLoginBoxesSignup .appleLogin {
  /* background: #111; */
  color: white;
}
.socialLoginBoxesSignup .facebookLogin {
  background: #0f3577;
}
.socialLoginBoxesSignup .googleLogin {
  color: black;
}

.socialLoginBoxesSignup a,
.socialLoginBoxesSignup button {
  /* width: 95px; */
  max-width: 25rem;
  height: 95px;
  /* background: #fff; */
  background: #f0f8ff;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border: none;

  /* box-sizing: border-box; */
  box-sizing: content-box;
  /* box-shadow: 0 4.35443px 26.1266px rgb(0 0 0 / 9%); */
  border-radius: 10.8861px;
  display: -webkit-flex;
  display: flex;

  flex-wrap: nowrap;
  flex-direction: row;

  justify-content: center;

  align-items: center;
  text-decoration: none;

  flex-direction: column;
}

.socialLoginBoxesSignup a span,
.socialLoginBoxesSignup button span {
  font-style: normal;
  font-weight: 500;
  font-size: 13.0633px;
  line-height: 16px;
  display: block;
  color: #000;
  text-decoration: none;
  margin: 15px 0 0;
}
.socialLoginBoxesSignup .facebookLogin span {
  color: #fff;
}
.socialLoginBoxesSignup .appleLogin span {
  color: #fff;
}

.forgot {
  text-align: center;
  cursor: pointer;
}

/* HOME BANNER SLIDER */
.homeSubFirstContainer .container {
  /* background: #d9cb9d; */
  /* border-radius: 20px; */
  border-radius: 5px 5px 10px 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: column;
  flex-direction: column;
  /* padding: 15px 0; */
  padding: 15px 0;
  /* min-height: 139px; */
  width: 99%;
}

.homeSubFirstContainer .subFirstSectionBtns {
  background: #000;
  border-radius: 8.23834px;
  height: 42px;
  font-weight: 900;
  /* width: 20%; */
  /* width: 20%; */
  font-size: 12px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.164767px;
  color: #fff;
  border: 0;
  padding: 10px 15px;
  font-family: roboto;
}
/* @media (min-width: 180px) and (max-width: 480px) {
  .homeSubFirstContainer .subFirstSectionBtns {
    width: 53%;
  }
} */
.homeSubFirstContainer h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 50px;
  color: #000;
  font-family: roboto;
  /* text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff; */
}
@media (max-width: 767px) {
  .homeSubFirstContainer h2 {
    font-size: 18px;
    line-height: 39px;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .homeSubFirstContainer {
    margin-top: -52px;
    margin-left: 2px;
    margin-right: 2px;
  }
}
@media (max-width: 768px) {
  .homeSubFirstContainer .container {
    border-radius: 11px;
    margin-top: 45px;
  }
}

.homeFirstContainerWithImg .container {
  min-height: 393px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  /* background-image: url('https://massi-bucket.s3.amazonaws.com/uploads/sliders/file-0.045445105034660926-1656300542274-uploads-0.7266536255398093.jpg'); */
  -webkit-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 767px) {
  .homeFirstContainerWithImg .container {
    margin-left: 60px;
  }
}
@media (max-width: 667px) {
  .homeFirstContainerWithImg .container {
    /* margin-left: 1px; */
    margin-left: 0px;
    /* background-image: url('https://massi-bucket.s3.amazonaws.com/uploads/sliders/file-0.03778641927554727-1685703883311-uploads-0.38961449611546506.jpg'); */
    background-position: center;
    /* margin-top: -40px; */
    /* margin-top: -86px; */
    /* margin-top: -55px; */
  }
}

/* @media (min-width: 412px) {
  .homeFirstContainerWithImg .container {
    margin-left: 0px;
  }
} */
/* @media (min-width: 820px) {
  .homeFirstContainerWithImg .container {
    margin-left: 50px;
  }
} */

/* .homeFirstContainerWithImg .container:before {
  background: rgba(0, 0, 0, 0.5);
  width: 99%;
  height: 100%;
  content: '';

  top: 0;
  position: absolute;
  border-radius: 10px;
} */
.homeFirstContainerWithImg h2,
.homeFirstContainerWithImg h4 {
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  color: #fff;
  /* color: black; */
  z-index: 1;
  width: 100%;
  text-align: center;
  font-family: roboto;
  /* text-shadow: 0px 7px 4px rgba(0, 0, 0, 0.5); */
  /* text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff; */
}

.homeFirstContainerWithImg h2 {
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 14px;
}
@media (max-width: 767px) {
  .homeFirstContainerWithImg h2 {
    font-size: 18px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 14px;
  }
}

.homeFirstContainerWithImg h4 {
  font-size: 22px;
  line-height: 33px;
  margin-bottom: 13px;
}
@media (max-width: 767px) {
  .homeFirstContainerWithImg h4 {
    font-size: 15px;
    line-height: 32px;
  }
}
.homeFirstContainerWithImg .headerSearchForm {
  /* margin-bottom: 23px;
  z-index: 1;
  margin-right: 0; */
  margin-bottom: 13px;
  z-index: 1;
  margin-top: 35px;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .homeFirstContainerWithImg .headerSearchForm {
    z-index: 1;
    margin-right: 30px;
  }
}

@media (min-width: 360px) and (max-width: 1023px) {
  .mobileSearchForm {
    /* padding: 0px 29px; */
    padding: 0px 29px 10px;
    display: flex !important;
  }
}
.desktopHide,
.mobileSearchForm {
  display: none;
}
@media (min-width: 360px) and (max-width: 1023px) {
  .mobileSearchForm .headerSearchForm {
    /* width: 100%; */
    /* max-width: 100%; */
    min-width: 100%;
    margin: 9px 0 0;
    display: none;
    border-radius: 6px;
  }
}

@media (min-width: 180px) and (max-width: 480px) {
  .searchVendorBtn span {
    display: block;
    color: #fff;
  }
}

/* .headerSearchForm {
  border: 2px solid #000;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 11px;
  max-width: 245px;
  height: 42px;
  display: -webkit-flex;
  display: flex;
  padding: 11px 13px;
  margin: 0 26px 0 0;

  background: #fff;
} */
.desktopSearchForm {
  border: 2px solid #000;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 11px;
  max-width: 245px;
  height: 42px;
  display: -webkit-flex;
  display: flex;
  padding: 11px 13px;
  margin: 0 26px 0 0;

  background: #fff;
}

.homeFirstSectionBtnsWrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  z-index: 0;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.homeFirstSectionBtnsWrap .firstSectionBtns {
  background: #d9cb9d;
  border-radius: 10px;
  height: 50px;
  text-align: center;
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #fff;
  border: 0;
  min-width: 200px;
  margin-right: 65px;
  margin-top: 40px;
  font-family: roboto;
}
/*  */

@media (min-width: 180px) and (max-width: 480px) {
  .homeFirstSectionBtnsWrap .firstSectionBtns {
    min-width: auto;
    margin-right: 3%;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: 103px;
    /* height: auto !important; */
    line-height: 15px !important;
  }
}
.homeFirstSectionBtnsWrap .firstSectionBtns:last-child {
  margin-right: 20px;
}
.homeFirstContainerWithImg .headerSearchForm {
  margin-bottom: 43px;
  z-index: 1;
  margin-right: 0;
}

.headerSearchForm img {
  margin-right: 23px;
  width: 20px;
}
/* .headerSearchForm input {
  border: 0;
  background: transparent;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  border: none;
  font-size: 14px;
  line-height: 18px;
  color: #edb300;
  font-family: roboto;
} */
.homeFirstContainerWithImg .headerSearchForm {
  margin-bottom: 13px;
  z-index: 1;
  margin-right: 0;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%);
}

.headerSearchForm input::placeholder {
  color: #edb300;
}
.headerSearchForm img {
  margin-right: 23px;
  width: 20px;
}
img,
svg {
  vertical-align: middle;
}

.vendor {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  text-overflow: ellipsis;
  /* overflow: hidden; */
  white-space: nowrap;
  line-height: 31px;
  color: #c49708 !important;
  /* margin-bottom: 39px; */

  font-family: roboto;
}
@media (min-width: 412px) and (max-width: 1199px) {
  .vendor {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
  }
}

/* Signin Modal */
.customFormGroup {
  display: -webkit-flex;
  display: flex;
  margin: 0 0 20px;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.customFormGroup .signUpBtn {
  background: #000;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0;
  width: 70%;
  height: 45px;
  /* margin: 22px 0 0; */
  font-family: roboto;
}
.formShortInfo {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;

  align-items: center;
  text-align: center;
  /* margin: 22px 0; */
  color: #7d7d7d;
}
.signUpWithBox,
.signUpWithBox span {
  text-align: center;
  position: relative;
}
.signUpWithBox {
  margin: 0px auto;
  width: 40%;
}
.signUpWithBox:before {
  content: '';
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: 100%;
  left: 0;
  top: 13px;
}
.signUpWithBox span {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  font-family: roboto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  background: #fff;
  color: #000;
  display: inline-block;
  z-index: 1;
  padding: 0 17px;
}

.socialLoginBoxes a span,
.socialLoginBoxes button span {
  font-style: normal;
  font-weight: 500;
  font-size: 13.0633px;
  line-height: 16px;
  display: block;
  color: #000;
  text-decoration: none;
  margin: 15px 0 0;
}
.socialLoginBoxes .facebookLogin span {
  color: #fff;
}
.socialLoginBoxes .appleLogin span {
  color: #fff;
}
.lost_password {
  text-decoration: none;
}
#forget {
  padding-left: 130px;
}

/* SEARCH BAR */
.searchbar {
  background-color: #fff7dc;
  /* border-radius: 10px; */
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.vendorForm .vendorSearch {
  background: #fff;
  border: 1px solid #000;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  text-indent: 24px;
  /* max-width: 312px; */
  min-width: 430px;
  height: 50px;
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #000;
  padding: 17px 20px;
  font-family: roboto;
  margin: 0 11px 0 0;
}

.vendorForm .vendorSearch:focus {
  background: #fff;
  border: 1px solid #000;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  /* max-width: 312px; */
  /* min-width: 295px; */
  border: none;
  height: 50px;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #000;
  padding: 17px 20px;
  font-family: roboto;
  margin: 0 11px 0 0;
}
@media (max-width: 768px) {
  .vendorForm .vendorSearch:focus {
    background: #fff;
    border: 1px solid #000;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 10px;
    /* max-width: 312px; */
    min-width: 100%;

    border: none;
    height: 58px;
    font-style: normal;
    /* font-weight: 600; */
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #000;
    padding: 17px 20px;
    font-family: roboto;
    margin: 0 11px 0 0;
  }
}
.vendorForm .vendorSearch {
  width: 100%;
  max-width: 400px; /* Set a maximum width for larger screens */
}

@media (max-width: 820px) {
  .vendorForm .vendorSearch {
    min-width: 100%;
  }
}
@media (max-width: 912px) {
  .vendorForm .vendorSearch {
    min-width: 100%;
  }
}

/* @media (max-width: 768px) {
  .vendorForm .vendorSearch {
    background: #fff;
    border: 1px solid #000;

    box-sizing: border-box;
    border-radius: 10px;
    border: none;
    min-width: 97%;
    height: 58px;
    font-style: normal;

    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #000;
    padding: 17px 20px;
    font-family: roboto;
    margin: 0 11px 0 0;
  }
}






} */

.vendorForm .vendorSelect1 {
  background: #fff;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 11px;
  /* max-width: 300px; */
  border: none;
  max-width: 193px;
  height: 50px;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 13px;
  letter-spacing: 0.2px;
  color: #000;
  padding: 6px 7px;
  font-family: roboto;
  margin: 0 11px 0 0;
}

@media (max-width: 768px) {
  .vendorForm .vendorSelect1 {
    width: 50%;
    /* float: left; */
    display: inline-block;
    margin-right: 0px;
    max-width: calc(50% - 10px);
    /* margin-top: 20px;
 
    margin: 0px;
    margin-top: 20px;
    display: inline-block;
    width: calc(50% - 10px); */
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .vendorForm .vendorSelect1 {
    min-width: 100%;
    margin-top: 4px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .vendorForm .vendorSelect2 {
    min-width: 100%;
    top: 8px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .vendorForm .vendorSelect3 {
    width: 100%;
    top: 10px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .vendorForm .vendorSelect4 {
    min-width: 100%;
    top: 4px;
  }
}

.vendorForm .vendorSelect2 {
  background: #fff;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 11px;
  /* max-width: 313px; */
  max-width: 160px;
  height: 50px;
  border: none;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 13px;
  letter-spacing: 0.2px;
  color: #000;
  padding: 6px 7px;
  font-family: roboto;
  margin: 0 11px 0 0;
}
@media (max-width: 768px) {
  .vendorForm .vendorSelect2 {
    /* margin-top: 30px; */
    /* bottom: 82px; */
    /* left: 174px; */
    /* left: 182px; */
    width: 50%;
    /* float: left; */
    display: inline-block;
    margin-right: 0px;
    max-width: calc(50% - 10px);
  }
}
.css-109onse-indicatorSeparator {
  background-color: #fff !important;
  width: 0px !important;
}
.vendorForm .vendorSelect3 {
  /* background: #fff;
  border: 1px solid #000;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 11px;
  border: none;
 
  max-width: 113px;
  height: 50px;
  font-style: normal;
 
  font-size: 14px;

  letter-spacing: 0.2px;
  color: #000;
  padding: 7px 20px;
  font-family: roboto;
  margin: 0 11px 0 0; */
  background: #fff;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 11px;
  border: none;
  /* max-width: 313px; */
  min-width: 100px;
  height: 50px;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 13px;
  letter-spacing: 0.2px;
  color: #000;
  padding: 5px 2px;
  font-family: roboto;
  margin: 0 11px 0 0;
}

@media (max-width: 768px) {
  .vendorForm .vendorSelect3 {
    display: inline-block;
    min-width: calc(50% - 2px);
    margin-right: 0px;
    margin-left: 8px;
    margin-top: 12px;
  }
}

.vendorForm .vendorSelect4 {
  background: #fff;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 11px;
  /* max-width: 300px; */
  max-width: 193px;
  height: 50px;
  font-style: normal;
  border: none;
  /* font-weight: 600; */
  font-size: 13px;
  /* letter-spacing: 0.2px; */
  color: #000;
  padding: 6px 7px;
  font-family: roboto;
  margin: 0 11px 0 0;
}

@media (max-width: 767px) {
  .vendorForm .vendorSelect4 {
    display: inline-block;
    /* min-width: calc(50% - 2px); */
    width: calc(50% - 2px);
    margin-right: 0px;
    margin-left: 8px;
    margin-top: 12px;

    /* width: calc(50% - 2px);
    display: inline-block;
    margin-left: 10px;
    margin-right: 0px; */
  }
}

.searchVendorBtn {
  /* background: #000;
  border-radius: 10px;
  height: 58px;
  border: 0;
  text-align: center;
  min-width: 100px; */
  background: #ffc50a;
  border-radius: 10px;
  height: 50px;
  border: 0;
  text-align: center;
  min-width: 55px;
}
@media (max-width: 768px) {
  .searchVendorBtn {
    margin-top: -20px;
    /* width: 100%; */

    width: 56%;

    bottom: 125px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .searchVendorBtn {
    margin-top: 20px;
  }
}

.resetVendorBtn {
  /* background: #000;
  border-radius: 10px;
  height: 58px;
  border: 0;
  text-align: center;
  min-width: 100px; */
  background: black;
  border-radius: 10px;
  height: 50px;
  border: 0;
  color: white;
  text-align: center;
  min-width: 50px;
}
@media (max-width: 768px) {
  .resetVendorBtn {
    margin-top: 13px;
    /* width: 100%; */
    width: 40%;
    /* position: relative;
    bottom: 110px;
    left: 190px; */
  }
}

.resetBtn {
  background: #fff7dc;
  border-radius: 10px;
  height: 58px;
  border: 0;
  text-align: center;
  min-width: 100px;
}
@media (max-width: 768px) {
  .resetBtn {
    margin-top: -20px;
    width: 100%;
  }
}

/* .searchVendorBtn span {
  display: none;
} */

/* Vendor Deals */
/* .vendorDeals {
  margin: 0 0 50px;
} */
.dealsHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  border-bottom: 0 solid #edb300;
  padding: 0 0 12px;
  letter-spacing: 1.68px;
  color: #000;
  margin-bottom: 3px;
  position: relative;
}
.dealsHeading:before {
  content: '';
  /* background: #edb300; */
  width: 17%;
  height: 1px;
  /* bottom: 4px; */
  position: absolute;
  left: 13%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.dealBoxWrap {
  background: #efefef;
  border-radius: 2px;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 5px 0px;
  margin: 0 0 20px 57px;
  /* padding: 37px 50px;
  margin: 0 0 0px 38px; */
  position: relative;
}
@media (min-width: 180px) and (max-width: 480px) {
  .dealBoxWrap {
    /* display: flex;
    flex-wrap: wrap;
    padding: 33px 26px;
    margin: 0px 9px 20px; */
  }
}
.dealBoxWrap:after,
.dealBoxWrap:before {
  content: '';
  width: 50px;
  height: 50px;
  position: absolute;
  top: 25%;
  border-radius: 100%;
}
.dealBoxWrap {
  left: -30px;
  background-size: cover;
}

.dealBoxWrap2 {
  background: #323232;
  border-radius: 2px;
  -webkit-flex-wrap: wrap;
  /* flex-wrap: wrap; */
  /* padding: 37px 50px; */
  margin: 0 0 0px 38px;
  /* position: relative; */
  min-width: 35%;
}
@media (min-width: 180px) and (max-width: 480px) {
  .dealBoxWrap2 {
    /* display: flex;@@ */
    flex-wrap: wrap;
    padding: 33px 26px;
    margin: 0px 9px 20px;
  }
}
.dealBoxWrap2:after,
.dealBoxWrap2:before {
  content: '';
  width: 50px;
  height: 50px;
  position: absolute;
  top: 25%;
  border-radius: 100%;
}
.dealBoxWrap2:before {
  background-image: url('https://www.beta.eventmassi.com/_next/static/media/without-dealText-icon.921574e3.png');
  left: -30px;
  background-size: cover;
}

.dealContentDetails {
  width: 100%;
}
b,
strong {
  font-weight: bolder;
}

.dealBoxWrap:after {
  background: #fff;
  right: -30px;
}

.dealBoxWrap:after,
.dealBoxWrap:before {
  content: '';
  width: 50px;
  height: 50px;
  position: absolute;
  top: 25%;
  border-radius: 100%;
}
.vendorSep {
  opacity: 0.6;
  border: 2px solid rgb(0 0 0 / 15%);
  /* box-shadow: 0 4px 11px rgb(0 0 0 / 15%); */
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.vendorName {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  font-family: roboto;
  color: #333;

  text-align: center;
  margin: auto;
}
.btn-close {
  right: 0px;
  background: none;
  opacity: 1;
  width: 21px;
  height: 35px;
  padding: 0;
  top: 25px;
  position: absolute;
  margin-left: 4px;
  margin-top: 0;
}
img,
svg {
  vertical-align: middle;
}
.already {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  font-family: roboto;
  color: #666;
  text-align: center;
}
@media (min-width: 768px) {
  .col-md-5 {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

@media (min-width: 768px) {
  .col-md-4 {
    width: 103.33333333%;
  }
}

/* test */
@media (min-width: 768px) {
  .col-md-4,
  .col-md-5 {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}
@media (min-width: 768px) {
  .col-md-4 {
    width: 33.33333333%;
  }
}
.homeSecondSectionBoxWrap {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
  border-radius: 10px;
  /* padding: 45px 0 20px; */
  padding: 39px 0 20px;
  /* min-height: 500px; */
  min-height: 400px;
  width: 100%;
  max-height: 500px;
}
.homeSecondSectionBoxWrap h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-transform: capitalize;
  color: #000;
  text-align: center;
  font-family: roboto;
}
/* 
@media (min-width: 180px) and (max-width: 480px) {
  .homeSecondSectionBoxWrap h4 {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
      0px 4px 4px rgba(0, 0, 0, 0.25);
  }
} */
@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.5rem;
  }
}
.fbGrpWrap {
  text-align: center;
  margin-top: 4px;
}
.homeSecondSectionBoxWrap h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
  color: #000;
  text-align: center;
  margin: 21px 0 7px;
  font-family: roboto;
}

.h5,
h5 {
  font-size: 1.25rem;
}
.homeSecondSectionBoxWrap ul {
  margin: 0;
  padding: 0;
  margin-left: 12px;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol,
ul {
  padding-left: 2rem;
}

.homeSecondSectionBoxWrap ul li {
  list-style: none;
  display: inline-block;
  margin: 0 21px 0 0;
}
/* @media (min-width: 481px) and (max-width: 767px) {
  .homeSecondSectionBoxWrap {
    padding: 15px 0;
    min-height: unset;
    margin-bottom: 12px;
  }
} */
/* @media (min-width: 393px) and (max-width: 480px) {
  .homeSecondSectionBoxWrap {
    padding: 15px 0;
    min-height: unset;
    margin-bottom: 12px;
  }
} */
img,
svg {
  vertical-align: middle;
}

@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.5rem;
  }
}
.newsLetterForm {
  padding: 30px 25px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

*,
:after,
:before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.newsLetterForm .customFormControl {
  border: 0;
  padding: 21px 14px;
  background: hsla(0, 0%, 77%, 0.4);
  border-radius: 5px;
  height: 52px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  /* text-transform: capitalize; */
  color: #000;
  text-align: left;
  margin-bottom: 30px;
  width: 100%;
  display: block;
  font-family: roboto;
}
.newsLetterForm .subscribeBtn {
  background: #ffc50a;
  border-radius: 7px;
  height: 48px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #000;
  font-family: roboto;
  border: 0;
}
@media (min-width: 180px) and (max-width: 480px) {
  .newsLetterForm .subscribeBtn {
    background: black;
    color: white;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .subscribeBoxWrap h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    text-transform: capitalize;
    color: #000;
    text-shadow: 2px 5px 3px #ccc;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .homeSecondSectionBoxWrap h1,
  .homeSecondSectionBoxWrap h5 {
    font-size: 16px;
  }
}
/* @media (min-width: 481px) and (max-width: 767px) {
  .homeSecondSectionBoxWrap {
    padding: 15px 0;
    min-height: unset;
    margin-bottom: 12px;
  }
} */

/* @media (min-width: 414px) {
  .homeSecondSectionBoxWrap {
    padding: 15px 0;
    min-height: unset;
    margin-bottom: 12px;
  }
}
@media (min-width: 375px) {
  .homeSecondSectionBoxWrap {
    padding: 15px 0;
    min-height: unset;
    margin-bottom: 12px;
  }
} */
@media (min-width: 360px) and (max-width: 414px) {
  .homeSecondSectionBoxWrap {
    padding: 15px 0;
    min-height: unset;
    overflow-x: hidden;
    margin-bottom: 12px;
  }
}

/* VENDOR LIST */
/* .homeTopPicksWrap {
  margin: 0 0 31px;
} */

.topPicksHeading {
  text-align: center;
  width: 100%;
  margin: 0 0 43px;
  clear: both;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.topPicksContentWrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.topPicksBoxWrap {
  display: -webkit-flex;
  display: flex;
  width: 50%;
  margin-bottom: 68px;
}
.topPicksBoxWrap .topPickPrdImg {
  display: -webkit-flex;
  display: flex;
  /* margin-right: 47px; */
  margin-right: 17px;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  cursor: pointer;
}
@media (min-width: 180px) and (max-width: 480px) {
  .topPicksBoxWrap .topPickPrdImg {
    display: -webkit-flex;
    display: flex;
    margin-right: 15px;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topPicksBoxWrap .topPickPrdImg img {
    border-radius: 100%;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topPickPrdContent {
    padding: 6px 0 0;
    width: 61%;
  }
}
.topPickPrdContent {
  padding: 6px 0 0;
  width: 46%;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .topPicksBoxWrap .topPickPrdImg img {
    border-radius: 100%;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}
.topPicksBoxWrap .topPickPrdImg img {
  border-radius: 100%;
  width: 158px;
  height: 158px;
  object-fit: cover;
}
.topPickPrdContent h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 31px;
  color: #c49708;
  /* margin-bottom: 39px; */
  /* text-overflow: ellipsis;
  overflow: hidden; */
  white-space: nowrap;
  font-family: roboto;
}
.prdlocation span {
  font-style: normal;
  /* font-weight: 600;
  font-size: 20px; */
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #000;
  /* font-family: 'Roboto'; */
}
.prdlocation img {
  margin: 0 10px 0 0;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .topPickBoxBtnsWrap .contactBtn,
  .topPickBoxBtnsWrap .dealsBtn {
    width: 50%;
  }
}

.topPickBoxBtnsWrap .contactBtn {
  /* background: #d9cb9d; */
  background: #ffc50a;
  border-radius: 8.23834px;
  font-style: normal;
  /* font-weight: 900;
  font-size: 12px;
  line-height: 23px; */
  font-weight: 400;
  font-size: 14px;
  line-height: 0px;
  text-align: center;
  letter-spacing: 0.164767px;
  color: #fff;
  border: 0;
  width: 60px;
  /* min-width: 146px; */
  /* height: 27px; */
  height: 37px;
  padding: 0;
  /* font-family: 'Roboto'; */
}
.topPickBoxBtnsWrap .dealsBtn {
  background: #c4c4c4;

  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.164767px;
  color: #fff;
  border: 0;
  width: 92px;
  height: 27px;
  padding: 0;
  font-family: roboto;
}

@media (min-width: 180px) and (max-width: 480px) {
  .topPicksHeading img {
    width: 80%;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topPicksHeading {
    margin: 0px 0 15px;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topPicksHeading img {
    width: 80%;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topPicksBoxWrap .topPickPrdImg {
    display: -webkit-flex;
    display: flex;
    margin-right: 15px;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topPicksBoxWrap .topPickPrdImg img {
    border-radius: 100%;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topPickPrdContent {
    padding: 6px 0 0;
    /* width: 61%; */
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topPickPrdContent h1 {
    font-size: 18px;
    line-height: 18px;
    overflow: hidden;
    margin-bottom: 5px;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topPickLocationPriceWrap {
    margin-bottom: 15px;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .prdlocation span {
    font-size: 18px;
    line-height: 27px;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topPickLocationPriceWrap {
    margin-bottom: 15px;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .topPickBoxBtnsWrap .contactBtn,
  .topPickBoxBtnsWrap .dealsBtn {
    /* width: 46%; */
    min-width: 60px;
    margin-left: -13px;
    height: 35px;
  }
}

@media (min-width: 180px) and (max-width: 480px) {
  .topPicksBoxWrap {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    margin-bottom: 42px;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 2px solid #dfdfdf;
    padding-bottom: 25px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .topPicksBoxWrap .topPickPrdImg img {
    border-radius: 100%;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}

/* MESSAGES */
.tabTopBar {
  min-height: 70px;
  background: #000;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto 40px;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.tabTopBar p {
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  font-family: roboto;
  text-align: center;
  color: #fff;
  margin: 0;
}
.tabTopBar p a {
  color: #edb300;
  text-decoration: none;
}

/* MASSI SLECETED USER */
.vendorselectedBoxesWrap {
  margin: 10px 0 20px;
}
.vendorselectedBoxesWrap h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 26px;
  color: #edb300;
  font-family: roboto;
  margin: 0 0 25px;
}

.vendorInsideContentDetails {
  display: -webkit-flex;
  display: flex;
  text-decoration: none;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 0 18px;
}

.vendorInsideContentDetails .vendorImgGallery .insideVendorGalleryWrap {
  width: 272px;
  height: 180px;
  cursor: pointer;
}

@media (min-width: 180px) and (max-width: 480px) {
  .vendorInsideContentDetails .vendorImgGallery .insideVendorGalleryWrap {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .vendorInsideContentDetails .vendorImgGallery {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
  }
}

@media (min-width: 180px) and (max-width: 480px) {
  .vendorInsideContentDetails .vendorImgGallery .insideVendorGalleryWrap {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .event {
    width: 100%;
    object-fit: cover;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .vendorSelectedStateWrap {
    max-width: 75%;
  }
}

@media (min-width: 180px) and (max-width: 480px) {
  .vendorRightSideDetails {
    width: 0%;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .wid100InsideVendor .insideVendorRightSideBtn {
    width: 30%;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .wid100InsideVendor .insideVendorRightSideBtn img {
    width: 70px;
  }
}
.vendorInsideContentDetails .vendorImgGallery .insideVendorGalleryWrap img {
  object-fit: cover;
}
.vendorMetaWrap {
  display: -webkit-flex;
  display: flex;
  margin: 18px 0 0;
}
.vendorMetaWrap .email {
  background: #ffc50a;
  box-shadow: 0 4px 20px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding: 11px 17px;
  text-align: center;
  width: 259px;
  margin: 0px 32px 0 0;
}
.vendorMetaWrap .vendorMetaBoxWrap:last-child {
  margin-right: 0;
}
.vendorMetaWrap .vendorMetaBoxWrap {
  background: #fff;
  box-shadow: 0 4px 20px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding: 11px 17px;
  margin: 0 13px 0 0;
}

.vendorMetaWrap3 .phone {
  background: #ffc50a;
  /* box-shadow: 0 4px 20px rgb(0 0 0 / 25%); */
  border-radius: 10px;
  padding: 11px 17px;
  /* width: 175px; */
  text-align: center;
  margin: 0 30px 0 0;
}
.vendorMetaWrap2 {
  display: -webkit-flex;
  display: flex;
  margin: 18px 0 0;
}

.vendorMetaWrap2 .phone {
  background: #ffc50a;
  box-shadow: 0 4px 20px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding: 11px 17px;
  width: 60px;
  /* width: 240px; */
  text-align: center;
  margin: 0 15px 0 0;
}
.vendorMetaWrap2 .vendorMetaBoxWrap {
  background: #fff;
  /* box-shadow: 0 4px 20px rgb(0 0 0 / 25%); */
  box-shadow: 0 0px 3px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding: 11px 17px;
  width: 60px;
  /* margin: 0 36px 0 -26px; */
  margin: 0 17px 0 -7px;
}
.vendorMetaWrapInquire {
  display: -webkit-flex;
  display: flex;
  margin: 0 0 0 -53px;
}
.vendorMetaWrapInquireExplore {
  display: -webkit-flex;
  display: flex;
  justify-content: center;
}
@media (min-width: 180px) and (max-width: 480px) {
  .vendorMetaWrapInquireExplore {
    margin: 0 0 0 -14px;
  }
}

.vendorMetaWrapInquire .phone {
  background: #ffc50a;
  /* box-shadow: 0 4px 20px rgb(0 0 0 / 25%); */
  border-radius: 10px;
  padding: 11px 17px;
  width: 60px;
  /* width: 215px; */
  text-align: center;
  margin: 0 15px 0 0;
}
.vendorMetaWrapInquireExplore .phone {
  background: #ffc50a;
  /* box-shadow: 0 4px 20px rgb(0 0 0 / 25%); */
  border-radius: 10px;
  padding: 11px 17px;
  /* width: 175px; */
  width: 65px;
  /* width: 200px; */
  text-align: center;
  margin: 0 15px 0 0;
}
.vendorMetaWrapInquireExplore .vendorMetaBoxWrap {
  background: #fff;
  /* box-shadow: 0 4px 20px rgb(0 0 0 / 25%); */
  box-shadow: 0 0px 3px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding: 11px 17px;
  width: 60px;
  /* margin: 0 36px 0 -26px; */
  margin: 0 17px 0 -7px;
}
.vendorMetaWrapInquire .vendorMetaBoxWrap {
  background: #fff;
  /* box-shadow: 0 4px 20px rgb(0 0 0 / 25%); */
  box-shadow: 0 0px 3px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding: 11px 17px;
  width: 60px;
  /* margin: 0 36px 0 -26px; */
  margin: 0 17px 0 -7px;
}

@media (min-width: 180px) and (max-width: 480px) {
  .vendorMetaWrap2 .vendorMetaBoxWrap {
    padding: 11px 8px;
  }
}

.vendorMetaWrap2 .vendorMetaBoxWrap:last-child {
  margin-right: 0;
}
@media (min-width: 180px) and (max-width: 480px) {
  .vendorMetaWrap2 {
    margin: 13px 10px 0px;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .vendorMetaWrap2 .phone {
    /* background: #ffc50a;
    box-shadow: 0 4px 20px rgb(0 0 0 / 25%);
    border-radius: 10px;
    padding: 11px 17px;
    width: 215px;
    text-align: center;
    display: inline-block;
    margin: 0 36px 0 0; */
    background: #ffc50a;
    box-shadow: 0 4px 20px rgb(0 0 0 / 25%);
    border-radius: 10px;
    /* padding: 11px 17px; */
    padding: 8px 17px;
    width: 246px;
    text-align: center;
    display: inline-block;
    /* margin: 0px 10px 0 -37px; */
    margin: 0px 10px 0 -26px;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .vendorMetaWrap3 .phone {
    background: #ffc50a;
    box-shadow: 0 4px 20px rgb(0 0 0 / 25%);
    border-radius: 10px;
    padding: 11px 10px;
    /* max-width: 50%; */
    max-width: 60px;
    text-align: center;
    display: inline-block;
    margin: 0 0 0 5px;
    flex: 1;
  }
}
.vendorMetaWrap3 .email {
  background: #fff;
  /* box-shadow: 0 4px 20px rgb(0 0 0 / 25%); */
  box-shadow: 0 0px 3px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding: 11px 14px;
  margin-left: -25px;
}
@media (min-width: 180px) and (max-width: 480px) {
  .vendorMetaWrap3 .email {
    margin-left: 5px;
  }
}
.vendorMetaWrap3 .share {
  background: #fff;
  /* box-shadow: 0 4px 20px rgb(0 0 0 / 25%); */
  box-shadow: 0 0px 3px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding: 10px 11px;
  margin-left: 6px;
}

/* .insideVendorRightSideDetails,
.vendorCat,
.vendorRightSideDetails {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
} */

/* .insideVendorRightSideDetails,
.vendorRightSideDetails {
  width: 75%;
} */

/* @media (min-width: 180px) and (max-width: 480px) {
  .insideVendorRightSideDetails,
  .vendorRightSideDetails {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
  }
} */

/* .vendorRightSideDetails {
  padding: 0 0 0 52px;
} */
.vendorCat {
  width: 100%;
  -webkit-align-items: center;
  align-items: center;
}

.vendorSelectedStateWrap {
  display: -webkit-flex;
  display: flex;

  flex-wrap: nowrap;
  width: 100%;
  -webkit-align-items: center;
  align-items: center;
}
.vendorlocation {
  display: -webkit-flex;
  display: flex;
  /* margin-right: 44px; */
}
.vendorlocation img {
  margin: 0 10px 0 0;
}
.selectedStateName span,
.vendorlocation span {
  font-style: normal;
  /* font-weight: 600; */
  color: #000;
  font-family: roboto;
}
.vendorlocation span {
  font-size: 24px;
  line-height: 36px;
}
.vendorPriceTag {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 15px;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  clear: both;
}
.insideVendorRightSideBtn .dealIconBtn img {
  width: 100px;
}
.vendor-name {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #edb300;
  font-family: roboto;
  margin: 0 0 25px;
}

.vendorPriceTag span {
  font-family: roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-top: 15px;
  color: #0f9f1d;
}
.insideVendorRightSideDetails,
.vendorCat,
.vendorRightSideDetails {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.vendorShortInfo {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;
}
.vendorShortInfo p {
  /* font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #5e5e5e;
  max-width: 450px;
  font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #5e5e5e;
  /* max-width: 450px;
  max-height: 4.7em;  */
  min-width: 290px;
  max-height: 5em;
  overflow: hidden;
  text-overflow: ellipsis;
  /* font-family: 'Roboto'; */
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.vendorSelectContentWrap {
  /* background: linear-gradient(to top, #f4f4f4 0%, #f9f9f9 10%); */
  background: transparent;
  background-color: white;

  padding: 10px;

  /* border-bottom: 5px solid #dfdfdf; */
  /* box-shadow: 0 4px 11px rgb(0 0 0 / 15%); */
}
.selectedStateName .stateName {
  /* font-size: 20px;
  line-height: 20px;
 
  background: #fff4d1;
  padding: 7px;
  border-radius: 10px; */
  font-size: 12px;
  line-height: 30px;
  /* margin-left: 2px; */
  background: #fff4d1;
  padding: 5px;
  margin: 1px;
  border-radius: 10px;
}
.selectedStateName .star-rating1 {
  font-size: 16px;
  line-height: 15px;
  margin-left: 3px;
  background: #fff4d1;
  padding: 4px;
  border-radius: 10px;
}

/* DEALS AND SPECIALS */
.dealsTopContentWrap {
  margin-bottom: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.dealsTopContentWrap h6,
.dealsTopContentWrap p {
  font-family: roboto;
  font-style: normal;
  text-align: center;
  color: #000;
  width: 100%;
}
.dealsTopContentWrap h6 {
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 5px;
}
.dealsTopContentWrap p {
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;
  margin-bottom: 0;
}

.dealsFormWrap {
  padding: 40px 20px;
  background: #f5f5f5;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 86px;
}
.dealsForm,
.dealsForm .dealFormGrp {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.dealsForm .dealFormGrp {
  margin-bottom: 25px;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.dealsForm,
.dealsForm .dealFormGrp {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.dealFormGrp .dealFormControl,
.dealFormGrp label {
  width: 100%;
  font-style: normal;
  font-family: roboto;
  color: #000;
}

.dealFormGrp label {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin-bottom: 12px;
}
label {
  display: inline-block;
}
.dealFormGrp .dealFormControl,
.dealFormGrp label {
  width: 100%;
  font-style: normal;
  font-family: roboto;
  color: #000;
}
.dealFormGrp .dealFormControl,
.dealFormGrp label {
  width: 100%;
  font-style: normal;
  font-family: roboto;
  color: #000;
}
.dealFormGrp textarea.dealFormControl {
  height: 176px;
  resize: none;
}

.dealFormGrp .dealFormControl {
  padding: 21px 14px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  height: 60px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  text-transform: none;
  text-align: left;
  margin-bottom: 0;
  display: block;
  letter-spacing: 0.2px;
  opacity: 1;
}
.dealsForm .dealFormGrp {
  margin-bottom: 25px;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.upload-btn-wrapper,
.upload-btn-wrapper .btn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  cursor: pointer;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 43px;
}
.dealFormGrp .dealUploadBtn {
  background: #000;
  border-radius: 10px;
  height: 50px;
  border: 0;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  font-family: roboto;
  letter-spacing: 0.2px;
  color: #fff;
  padding: 0 66px;
  margin: 0;
}
.dealsForm .dealBtnGrp {
  margin: 0 auto !important;
  -webkit-justify-content: center;
  justify-content: center;
}
.upload-btn-wrapper,
.upload-btn-wrapper .btn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  cursor: pointer;
}
.upload-btn-wrapper .btn {
  background: rgba(255, 195, 10, 0.05);
  border: 2px dashed #000;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  height: 84px;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.upload-btn-wrapper input[type='file'] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
@media (min-width: 180px) and (max-width: 480px) {
  .vendorBrowseContentListingBox,
  .vendorPreferredContentWrap .vendorCat,
  .vendorPreferredContentWrap
    .vendorRightSideDetails
    .insideVendorRightSideDetails,
  .vendorPreferredContentWrap .vendorSelectedStateWrap {
    -webkit-justify-content: center;
    justify-content: center;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .vendorMetaWrap {
    margin: 3px 0 0px;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
  }
}

/* Massi preffered */
.listing-item-image {
  width: 120px;
}
.listing-item-container.list-layout {
  /* background: transparent;
  background-color: #f9f9f9; */
  margin-bottom: 25px;
}
/* @media (min-width: 180px) and (max-width: 480px) {
  .listing-item-container.list-layout {
    border-bottom: 5px solid #dfdfdf;
    padding: 0px 0px 20px 0px;
  }
} */

.listing-item-container {
  /* background: linear-gradient(to top, #f4f4f4 0%, #f9f9f9 10%);
  background-color: #f6f6f6; */
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  /* transform: translate3d(0, 0, 0);
  transition: transform 0.3s; */
  margin-bottom: 30px;
}
.listing-item-container.list2 {
  /* background: linear-gradient(to top, #f4f4f4 0%, #f9f9f9 10%);
  background-color: #f6f6f6; */
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  /* transform: translate3d(0, 0, 0);
  transition: transform 0.3s; */
  margin-bottom: 30px;
}

/* @media (max-width: 767px) {
  .listing-item-container {
    height: 100%;
    width: 100%;
  }
} */

.listing-item-container.list-layout .listing-item {
  display: flex;
  background-color: transparent;
  height: 220px;
}
@media (min-width: 180px) and (max-width: 480px) {
  .listing-item-container.list-layout .listing-item {
    height: auto;
  }
}
.listing-item {
  overflow: hidden;
}
.listing-item {
  background: white;
  border-radius: 4px 4px 0 0;
  height: 100%;
  display: block;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  height: 265px;
  z-index: 100;
}
a,
button {
  outline: none !important;
}
.listing-item-container.list-layout .listing-item-image {
  min-height: 200px;
  flex: 2;
  height: 100%;
  overflow: hidden;
  border-radius: 4px 0 0 4px;
  position: relative;
}
@media (min-width: 180px) and (max-width: 480px) {
  .listing-item-container.list-layout .listing-item-image {
    min-height: 140px;
  }
}

.each-fade {
  height: 100%;
}
.listing-item-container.list-layout .listing-item img {
  border-radius: 0;
  object-fit: cover;
  border-radius: 31px;
}

.listing-item-container.list-layout .listing-item-content {
  flex: 5;
  position: relative;
  bottom: 0;
  left: -30px;
  padding: 0;
  width: 100%;
  z-index: 50;
  box-sizing: border-box;
  display: none;
}
.listing-item-container.list-layout .listing-item-inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 35px;
  padding-right: 120px;
}
.listing-item-container.list-layout .listing-item-inner h3 {
  color: #333;
}
.listing-item-content h3 {
  color: black;
  font-size: 20px;
  bottom: -1px;
  position: relative;
  font-weight: 500;
  margin: 0;
  line-height: 31px;
}

/* Last list */
@media (max-width: 767px) {
  .listing-item img {
    /* object-fit: cover; */
    background: white;
  }
}
@media (max-width: 767px) {
  .normal-vendor-list-item .listing-item-container {
    flex-direction: row;
  }
}

@media (max-width: 767px) {
  .normal-vendor-list .rating-counter {
    font-size: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.name {
  font-size: 31px;
  font-weight: 500;
}

.massiDeals {
  filter: brightness(2) invert(1);
}
@media (max-width: 767px) {
  .massiDeals {
    justify-content: center;
    margin: auto;
    display: flex;
  }
}

/* EXPLORE VENDOR */

.exploreCarouselWrapper,
.exploreImgCarouselWrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.exploreCarouselWrapper {
  width: 100%;
  margin: 0;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.exploreImgCarouselWrap {
  position: relative;
  max-width: 150px;
}
.exploreImgCarouselWrap .favStar {
  position: absolute;
  right: 0;
}
.exploreImgCarouselWrap .exploreActualImg {
  width: 150px;
  height: auto;
  object-fit: cover;
}
.carouselArrowWrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 82%;
  /* margin: 35px 0 0; */
}
.carouselArrowWrap .nextArrow {
  margin-left: auto;
}
@media (min-width: 180px) and (max-width: 480px) {
  .carouselArrowWrap {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    margin: -150px 0 0;
  }
}

/* BULLETS */

/*  */
.image-gallery-bullets {
  top: 0 !important;
  left: 0 !important;
  margin: 0 auto !important;
  position: absolute !important;
  right: 0 !important;
  width: 80% !important;
  z-index: 4 !important;
  display: flex !important;
  justify-content: center !important; /* Center the bullets */
}

.image-gallery-bullets .image-gallery-bullet.active {
  background: white !important;
  transform: scaleX(1.2) rotate(360deg); /* Scale width and apply a 360-degree rotation for the active bullet */
  transform-origin: center !important; /* Rotate around the center of the bullet */
}

.image-gallery-bullets .image-gallery-bullet {
  appearance: none !important;
  background-color: lightgray !important;
  border: none !important;
  border-radius: 2px !important; /* Rounded corners for the bullets */
  cursor: pointer !important;
  display: inline-block !important;
  width: 60px !important; /* Width of the bullets */
  height: 3px !important; /* Set the height of the bullets */
  margin: 0 5px !important;
  outline: none !important;
  padding: 0 !important;
  transition: transform 1s ease-in !important; /* Animate the transform property */
}

@keyframes active-loading {
  0% {
    transform: scaleX(0);
    transform-origin: left center;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left center;
  }
  100% {
    transform: scaleX(1);
    transform-origin: left center;
  }
}

.image-gallery-bullets .image-gallery-bullet.active {
  background: white !important;
  position: relative;
}

.image-gallery-bullets .image-gallery-bullet.active:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: lightgray; /* Background color to cover inactive bullets */
  animation: active-loading 14s infinite;
}

/* .image-gallery-bullets {
  top: 0px !important;
  left: 0 !important;
  margin: 0 auto !important;
  position: absolute !important;
  right: 0 !important;
  width: 80% !important;
  z-index: 4 !important;
}

.image-gallery-bullets .image-gallery-bullet.active {
  transform: scale(1.2) !important;
  border: 1px solid #fff !important;
  background: white !important;
}

.image-gallery-bullets .image-gallery-bullet {
  appearance: none !important;
  background-color: lightgray !important;
  border: none !important;
  border-radius: 17% !important;
  box-shadow: 0 2px 2px #1a1a1a !important;
  cursor: pointer !important;
  display: inline-block !important;
  width: 53px !important;
  margin: 0 5px !important;
  outline: none !important;
  padding: 2px !important;
  transition: all 0.2s ease-out !important;
} */

.slick-prev:before {
  background: whitesmoke !important;
  margin-left: 0px !important;
}
.slick-next:before {
  background: whitesmoke !important;
}
/* .carslider {
  border-radius: 15px !important;
} */

@media (max-width: 768px) {
  .carslider-wrapper {
    height: 393px;
    overflow: hidden;
  }
}
.carslider-wrapper {
  display: grid;
}

@media (max-width: 768px) {
  .carslider {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

@media (max-width: 768px) {
  .contentContainer .homeFirstContainerWithImg .container .carslider {
    /* display: none; */
    /* height: 300px; */
    /* max-height: 260px; */
    /* max-height: 32vh; */
  }
}

.carousel-caption {
  position: absolute;
  right: 15%;
  /* bottom: 25.25rem !important; */
  /* bottom: 6.25rem !important; */
  /* bottom: 15.25rem !important; */
  bottom: 11.25rem !important;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

@media (min-width: 180px) and (max-width: 820px) {
  .carousel-caption {
    /* top: 9.25rem !important; */
    top: 18.25rem !important;
  }
}
.carousel-image {
  position: relative;
  height: 300px;
  background-size: cover;
  background-position: center;
}

.carousel-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  /* border-radius: 10px !important; */
  border-radius: 10px 10px 10px 10px !important;
}

/* NEW 1st march */
.vendor-list-item-container {
  margin-bottom: 25px;
}
/* Mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
  /* Add your styles for mobile devices here */
  .row {
    flex-wrap: wrap;
  }

  .vendor-list-item-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .vendorImgGallery {
    margin-bottom: 1rem;
  }
  /* .selectedStateName {
    margin-top: 1rem;
  } */
  .vendorMetaWrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

/* Tablets and small desktops */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* Add your styles for tablets and small desktops here */
}

/* Large desktops and laptops */
@media only screen and (min-width: 992px) {
  /* Add your styles for large desktops and laptops here */
}
.event {
  /* height: 400px;
  width: 450px; */
  /* height: 300px; */
  /* width: 450px; */
  /* width: 727px; */
  height: 255px;
  /* width: 450px; */
  /* max-width: 210px; */
  max-width: 186px;

  object-fit: contain;
}

@media only screen and (max-width: 767px) {
  .event {
    /* height: 400px;
    width: 450px;
    object-fit: cover; */
    height: 315px;
    /* min-width: 340px; */
    min-width: 365px;
    max-width: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
}
.listing-item {
  display: flex; /* Set the container to use flexbox */
  flex-direction: row; /* Align the items horizontally */
}

.business-name {
  margin-left: 16px; /* Add some space between the image and the business name */
}
.selectCatName {
  font-size: 12px;
  /* font-weight: 600; */

  background: #fff4d1;
  /* padding: 7px; */
  border-radius: 10px;
}
.selectCatNamePreferred {
  font-size: 12px;
  /* font-weight: 600; */
  background: #fff4d1;
  padding: 4px;

  border-radius: 10px;
}

@media only screen and (max-width: 767px) {
  .selectCatNamePreferred {
    margin-left: 10px;
    font-size: 10px;
    padding: 2px;
  }
}

.vertical-line {
  border-left: 2px solid #ccc;
  height: 60px; /* set the height of the line */
  margin-right: 10px;
}

@media (max-width: 767px) {
  .vertical-line {
    border-left: none;
    border-top: 2px solid #ccc;
    width: 100%;
    margin-top: 25px;
    margin-bottom: -50px;
    display: block;
  }
}

/* PREFERRED */
.vendor-info-container {
  display: block;
  align-items: center;
}

.vendor-image-container {
  margin-right: 20px;
}

@media only screen and (max-width: 600px) {
  .vendor-info-container {
    display: flex;
  }
  .vendor-image-container {
    /* margin-bottom: 20px; */
    margin-right: 0;
  }
}
.prefer {
  height: 170px;

  /* background: whitesmoke; */
  width: 172px;
  object-fit: contain;
  /* border-radius: 10px; */
  border: 1px solid gainsboro;
  border-radius: 100%;
}
@media only screen and (max-width: 767px) {
  .prefer {
    /* height: 150px;
    width: 177px;
    object-fit: cover; */
    margin: 10px;
    height: 92px;
    max-width: 98px;
    object-fit: contain;
    border-radius: 50%;
  }
}

/* SEARCH */
@media (min-width: 1400px) {
  .searchContainer {
    min-width: 1690px;
    margin-left: -190px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
}

/* SEARCH ICON */
@media (max-width: 768px) {
  .searchVendorBtn .search-icon {
    display: none;
  }
  .searchVendorBtn .search-text {
    display: inline;
    font-size: 14px;
  }
  .resetVendorBtn {
    font-size: 14px;
  }
}

/* TINYMCE */
.list-box-listing-content .tox .tox-statusbar {
  background: none !important;
}
.list-box-listing-content .tox .tox-edit-area__iframe {
  background: none !important;
}
.dealBoxWrap .tox .tox-edit-area__iframe {
  background: none !important;
}
.dealBoxWrap .tox .tox-statusbar {
  background: none !important;
  border: none !important;
}

.vendor-profile-section .listing-section .tox-tinymce {
  border: none !important;
}
.vendor-profile-section .listing-section .tox .tox-statusbar {
  background: none !important;
  border: none !important;
}

.dealBoxWrap .tox-tinymce {
  border: none !important;
}

/* home;list vendor section state change */
.vendorCat .selectedCat .selectStateName span {
  background: #fff4d1;
  border-radius: 8.4507px;
  font-style: normal;
  font-weight: 500;
  font-size: 12.3662px;
  line-height: 17px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-family: roboto;
  color: #2e2e2e;
  height: auto;
  padding: 5px 9px 5px 9px;
  margin: 2px -16px 0 20px;
  /* margin: 5px 0 0 8px; */
  display: inline-block;
}
.vendorCat .selectedCat .selectStateName {
  line-height: 55px;
}
.btn-container {
  display: flex;
  align-items: center;
}
.contactBtn {
  margin-right: 10px;
}
.topPickBoxBtnsWrap
  .btn-container
  .vendorMetaWrapHomeListVendor
  .vendorMetaBoxWrap {
  background: #fff;
  /* box-shadow: 0 4px 20px rgb(0 0 0 / 25%); */
  box-shadow: 0 0px 3px rgb(0 0 0 / 25%);
  border-radius: 10px;
  /* padding: 11px 17px; */
  padding: 8px 12px 5px;
  width: 50px;
  /* margin: 0 36px 0 -26px; */
  margin: 0 12px 0 -7px;
}
@media (max-width: 768px) {
  .topPickBoxBtnsWrap
    .btn-container
    .vendorMetaWrapHomeListVendor
    .vendorMetaBoxWrap {
    width: 46px;
    height: 36px;
  }
}
@media (max-width: 768px) {
  /* Change the screen size value as per your requirement */
  .btn-container {
    display: flex;
    flex-direction: row-reverse;
    /* justify-content: flex-end; */
    justify-content: center;
  }
}

.listing-address-explore {
  display: inline-flex;
  align-items: center;
  padding: 8px 18px;
  justify-content: center;
  gap: 10px;
  margin: 3px;
  /* width: 28%; */
  height: 33px;
  left: 68px;
  font-size: 15px;
  font-weight: 600;
  background: #f1ecdb;
  /* border: 1.5px solid #d9cb9d; */
  border-radius: 6px;
  border: 2.5px solid rgba(217, 203, 157, 1);
}
@media (min-width: 180px) and (max-width: 920px) {
  .listing-address-explore {
    width: 35%;
  }
}
.listing-address {
  display: inline-flex;
  align-items: center;
  /* padding: 8px 18px; */
  padding: 8px 0px;
  justify-content: center;
  gap: 10px;
  margin: 3px;
  width: 20%;
  height: 33px;
  left: 68px;
  font-size: 15px;
  font-weight: 600;
  background: #f1ecdb;
  /* border: 1.5px solid #d9cb9d; */
  border-radius: 6px;
  border: 2.5px solid rgba(217, 203, 157, 1);
}
@media (min-width: 180px) and (max-width: 920px) {
  .listing-address {
    width: 38%;
  }
}
.listing-price {
  display: inline-flex;
  align-items: center;
  padding: 8px 18px;
  justify-content: center;
  gap: 10px;

  font-size: 15px;
  font-weight: 600;
  background: #f1ecdb;
  /* border: 1.5px solid #d9cb9d; */
  border-radius: 6px;
  border: 2.5px solid rgba(217, 203, 157, 1);
}
@media (min-width: 180px) and (max-width: 480px) {
  .listing-price {
    width: 35%;
  }
}

/* VENDOR DETAIl */
.social-icon-box {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 33px;
  border-radius: 5px;
  border: 1px solid #c7c7c7;
  padding: 5px;
  margin: 17px 23px 0px 1px;
}

.social-icon-box img {
  width: 17px;
  height: 17px;
  margin-top: 13px;
}

.social-icon-box p {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin-left: -23px;
  margin-top: 6px;
}
.inquireVendor {
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid #c7c7c7;
}
.inquireVendorExplore {
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
  border: 2px solid #c7c7c7;
}

/* VENDOR CATEGORy */
.categoryList {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
}
@media (min-width: 180px) and (max-width: 480px) {
  .categoryList {
    width: 65%;
  }
}
.keywordList {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
}
@media (min-width: 180px) and (max-width: 480px) {
  .keywordList {
    width: 65%;
  }
}

.categoryItem {
  flex-basis: calc(33.33% - 10px);

  text-align: center;
}

.categoryItem:last-child {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  .categoryItem {
    flex-basis: calc(50% - 10px);
  }
}
.imageRight {
  float: left;
  margin-left: 10px;
}

.textRight {
  overflow: hidden;
}
