.payment-confirm {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
}
.payment-confirm h1 {
  font-weight: 600;
  color: #ffc50a;
  margin-top: 30px;
}
.payment-confirm a {
  text-decoration: none;
  color: #ffc50a;
}
.payment-confirm span {
  font-size: 15px;
  margin-top: 10px;
}
