.contactBottomDetails {
  max-width: 576px;
  margin: auto;
  text-align: center;
  padding: 30px 0 0;
}

.contactBottomDetails p {
  font-weight: 400;
  font-style: normal;

  text-transform: capitalize;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}
.details {
  background: #ffffff;
  box-shadow: 0px 0px 29px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 20px;
}

@media (min-width: 375px) and (max-width: 912px) {
}

form .contact-form {
  height: 35px;
  line-height: 51px;
  padding: 0 20px;
  outline: none;
  font-size: 15px;
  margin: 0 0 16px 0;
  width: 100%;
  opacity: 1;
  border-radius: 14px;
  background: transparent;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  padding: 0;
  font-style: normal;
  resize: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  color: #000;

  border-bottom-color: red;
}
label sup {
  color: #cb0000;

  font-size: 12px;
}

sup {
  top: -0.5em;
}
sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
.radioButtonGrp {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
}

.radioButtonGrp {
  width: 100%;
}

.contactFormWrap .form-group label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-transform: capitalize;
  color: #000;

  margin-bottom: 20px;
}
.customRadioBtnContainer {
  position: relative;
  padding-left: 42px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 33px;
  -webkit-align-items: center;
  align-items: center;
}

.customRadioBtnContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.customRadioBtnContainer input:checked ~ .checkmark {
  background-color: #fff;
  border: 1px solid #edb300;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 14px;
  height: 19px;
  width: 19px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.6);
}
.customRadioBtnContainer input:checked ~ .checkmark:after {
  display: block;
}

.customRadioBtnContainer .checkmark:after {
  top: 5px;
  left: 5px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #edb300;
}
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

label span,
legend span {
  font-weight: normal;
  font-size: 14px;
  color: #444;
  margin-top: 10px;
}
@media (min-width: 180px) and (max-width: 480px) {
  .radioButtonGrp {
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}
