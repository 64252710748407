.products-container {
  width: 90%;
  min-height: 500px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  width: 53%;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 80px;
}
hr {
  border: 1px solid black;
}

.cards {
  border-radius: 13px;
  border: 2px solid black;
}

/*  */
.firstBlogPost {
  margin-bottom: 90px;
}

.blogPostWrap {
  background: #fff;
  box-shadow: 0 4.67866px 19.2998px rgb(0 0 0 / 10%);
  border-radius: 11.6966px;
  margin-bottom: 30px;
}
.insideBlogPost {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .firstBlogPost .insideBlogPost .blogImgWrap {
    display: -webkit-flex;
    display: flex;
    max-width: 300px;
  }
}

.firstBlogPost .insideBlogPost .blogImgWrap {
  display: -webkit-flex;
  display: flex;
  max-width: 433px;
}
.insideBlogPost .blogImgWrap {
  display: -webkit-flex;
  display: flex;
  padding: 7px;
  width: 100%;
}
.insideBlogPost .blogImgWrap img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 4.5px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .firstBlogPost .insideBlogDetails {
    display: -webkit-flex;
    display: flex;
    width: 56%;
    padding: 22px;
  }
}

.firstBlogPost .insideBlogDetails {
  display: -webkit-flex;
  display: flex;
  width: 61%;
  padding: 22px;
}
.insideBlogDetails {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  padding: 12px 24px 18px;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.firstBlogPost .insideBlogDetails h4 {
  font-size: 19.7885px;
  line-height: 30px;
  margin-bottom: 22px;
}

.insideBlogDetails h4,
.insideBlogDetails p {
  font-style: normal;
  /* font-family: 'roboto' !important; */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.insideBlogDetails h4 {
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  text-transform: capitalize;
  color: #2c2c2c;
  margin-bottom: 8px;
}
.firstBlogPost .insideBlogDetails p {
  margin-bottom: 22px;
}

.firstBlogPost .insideBlogDetails p {
  text-overflow: unset;
}
.insideBlogDetails p {
  font-weight: 400;
  font-size: 12.5927px;
  line-height: 19px;
  color: #6e6e6e;
  margin-bottom: 15px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.insideBlogDetails h4,
.insideBlogDetails p {
  font-style: normal;
  /* font-family: 'roboto' !important; */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.blogPostTime span,
.readMoreLink {
  display: -webkit-flex;
  display: flex;
}

.blogPostTime span {
  font-style: normal;
  font-weight: 500;
  font-size: 10.7937px;
  line-height: 16px;
  -webkit-align-items: center;
  align-items: center;
  color: #6e6e6e;
  /* font-family: 'roboto' !important; */
}
@media (min-width: 180px) and (max-width: 768px) {
  .firstBlogPost .insideBlogDetails {
    width: 100%;
  }
}
