.product-info {
  padding: 0 16px;
}
.monthly {
  color: #6f6c90;
}
.business {
  color: #6f6c90;
  font-family: 'Roboto';
  font-style: normal;

  font-size: 14px;
}
h3.product-title {
  font-size: 28px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}

.subscriptionMainWrap .container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
}
@media (min-width: 180px) and (max-width: 480px) {
  .subscriptionMainWrap {
    flex-direction: column;
  }
}

@media (min-width: 180px) and (max-width: 480px) {
  .subscriptionMainWrap .subscribeBoxWrap {
    max-width: 100% !important;
    margin-right: 0 !important;
    box-shadow: 0 0 0 !important;
    margin-bottom: 50px;
  }
}
.overlayView > a.testMode {
  display: none !important;
}
.overlayView .brandingLogoView {
  display: none !important;
}

/* Sub packages */
.subscriptionMainWrap {
  display: -webkit-flex;
  display: flex;

  flex-wrap: nowrap;
  width: 100%;
  padding-bottom: 80px;
}
.subscriptionMainWrap .container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
}
.subscriptionMainWrap .subscribeBoxWrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 335px;
  margin-right: 20px;
}
.subscriptionMainWrap .subscribeBoxWrap .subscribeBoxHeader {
  position: relative;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
  display: flex;
}
.subscriptionMainWrap .subscribeBoxWrap .subscribeBoxHeader img {
  height: 190px;
  width: 100%;
}
.subscriptionMainWrap
  .subscribeBoxWrap
  .subscribeBoxHeader
  .insideHeaderContent {
  position: absolute;
  top: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.subscriptionMainWrap
  .subscribeBoxWrap
  .subscribeBoxHeader
  .insideHeaderContent
  h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  color: #fff;
}
.subscriptionMainWrap
  .subscribeBoxWrap
  .subscribeBoxHeader
  .insideHeaderContent
  h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  text-align: center;
  color: #fff;
  font-family: roboto;
  margin-bottom: 0;
}
.form-switch {
  padding-left: 2.5em;
}
.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.subscriptionMainWrap .form-switch .form-check-input[type='checkbox'] {
  border-radius: 2em;
  height: 30px;
  width: 50px;
  margin-bottom: 8px;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;

  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
.subscriptionMainWrap
  .subscribeBoxWrap
  .subscribeBoxHeader
  .insideHeaderContent
  h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  font-family: roboto;
  text-align: center;
  color: hsla(0, 0%, 100%, 0.8);
  margin-bottom: 0;
}
.subscriptionMainWrap .subscribeBoxWrap .subscribeBoxBottomContent,
.subscriptionMainWrap
  .subscribeBoxWrap
  .subscribeBoxBottomContent
  .insideSubBottomBoxContent {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.subscriptionMainWrap .subscribeBoxWrap .subscribeBoxBottomContent {
  width: 100%;
  position: relative;
}
.subscriptionMainWrap
  .subscribeBoxWrap
  .subscribeBoxBottomContent
  .insideSubBottomBoxContent {
  position: absolute;
  top: 0;
  height: 100%;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.subscriptionMainWrap
  .subscribeBoxWrap
  .subscribeBoxBottomContent
  .insideSubBottomBoxContent
  span {
  font-style: normal;
  font-weight: 500;
  font-size: 33px;
  line-height: 50px;
  text-align: center;
  color: #fff;
  font-family: roboto;
}
.subscriptionMainWrap
  .subscribeBoxWrap
  .subscribeBoxMiddleContent
  .insideSubMiddleContentWrap
  ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.subscriptionMainWrap
  .subscribeBoxWrap
  .subscribeBoxMiddleContent
  .insideSubMiddleContentWrap
  ul
  li:nth-child(odd) {
  background: #fff;
}

.subscriptionMainWrap
  .subscribeBoxWrap
  .subscribeBoxMiddleContent
  .insideSubMiddleContentWrap
  ul
  li {
  display: -webkit-flex;
  display: flex;
  margin: 0;
  list-style: none;
  width: 100%;
  padding: 8px 16px;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  min-height: 58px;
}
.subscriptionMainWrap
  .subscribeBoxWrap
  .subscribeBoxMiddleContent
  .insideSubMiddleContentWrap
  ul
  li:nth-child(2n) {
  background: #f3f3f3;
}

/*  */
.box {
  background-color: red;
}
.money {
  color: silver;
}

/* Label switch */
.monthlylabel {
  margin: -72px;
}
.annuallyylabel {
  margin: 142px;
}
